<template>
  <div
    class="flex rounded-md ring-1 ring-inset ring-input focus-within:ring-primary focus-within:hover:ring-primary"
    :class="{
      'bg-gray-50 text-paragraph/50': disabled || pending,
      'ring-red-500': errors,
      'hover:ring-input-hover': !errors,
    }"
  >
    <input
      v-if="!textarea"
      type="text"
      :name="name"
      :id="name"
      v-bind="$attrs"
      :value="modelValue"
      class="form-input grow border-none bg-transparent px-4 py-3 text-sm focus:outline-none focus:ring-0"
      :class="{ 'cursor-not-allowed': disabled || pending }"
      :disabled="disabled"
      @input="emit('update:modelValue', $event.target.value)"
      @blur="onBlur"
    />
    <textarea
      v-else
      :name="name"
      :id="name"
      class="file-textarea grow border-none bg-transparent px-4 py-3 text-sm focus:outline-none focus:ring-0"
      :class="{ 'cursor-not-allowed': disabled || pending }"
      rows="3"
      v-bind="$attrs"
      @input="emit('update:modelValue', $event.target.value)"
      @blur="onBlur"
      :disabled="disabled"
      >{{ modelValue }}</textarea
    >
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: string | null;
    name: string;
    disabled?: boolean;
    textarea?: boolean;
  }>(),
  {
    disabled: false,
    textarea: false,
  }
);

const emit = defineEmits(["update:modelValue"]);
const { autosave, pending, getFieldErrors, submit } = injectStrict<ProvideForm>("form");
const errors = getFieldErrors(props.name);

const onBlur = () => autosave && submit();
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
